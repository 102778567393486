<template>
	<v-list-item>
		<v-list-item-avatar>
			<v-icon>mdi-account-plus</v-icon>
		</v-list-item-avatar>

		<v-list-item-content>
			<v-form ref="form" v-model="valid">
				<p>{{ $t('companies.admininistratorsHelp1') }}</p>
				<v-text-field
					v-model="email"
					:label="$t('auth.email')"
					:hint="$t('companies.admininistratorsHelp2')"
					:rules="rules.email"
					persistent-hint
					required
					outlined
					rounded
					hide-details="auto"
					class="pb-3"
				/>
			</v-form>
		</v-list-item-content>

		<v-list-item-action>
			<v-btn icon @click="callInviteAdmin()">
				<v-icon color="success">
					mdi-plus-circle-outline
				</v-icon>
			</v-btn>
		</v-list-item-action>
	</v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CompanyAdministratorsInviteForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			valid: false,
			email: '',
			rules: {
				email: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.indexOf('@') !== 0) || this.$t('rules.emailUsername'),
					(v) => (v && v.includes('@')) || this.$t('rules.emailAtSymbol'),
					(v) => (v && v.includes('.')) || this.$t('rules.emailPeriodSymbol'),
					(v) => (v && v.indexOf('.') <= v.length - 3) || this.$t('rules.emailDomain'),
					(v) => (v && /.+@.+\..+/.test(v)) || this.$t('rules.emailValid'),
					(v) => (v && v.length <= this.emailMaxLength) || this.$t('rules.length', { length: this.emailMaxLength })
				]
			}
		}
	},
	computed: {
		joinedAdministrators() {
			return this.company.administrators.administrators.filter((admin) => admin.joined)
		},
		invitedAdministrators() {
			return this.company.administrators.administrators.filter((admin) => admin.invited)
		},
		emailMaxLength() {
			return 255
		},
		...mapGetters({
			company: 'companies/company'
		})
	},
	methods: {
		callInviteAdmin() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.inviteAdmin({ companyID: this.company.id, email: this.email })
		},
		...mapActions('companies', ['inviteAdmin'])
	}
}
</script>
